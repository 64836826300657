import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import { List, ListItem } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";

import Ami80Per from "./images/AMI80PerImg.png";

import SFDocs from "./SFDocs.pdf";

const imgStyle = {
	height: 65,
	// maxWidth: "80%",
	objectFit: "cover",
	display: "block",
	margin: "0 auto",
	marginTop: 10,
	marginBottom: 10,
};

// const [open, setOpen] = React.useState(false);

const increaseInExpObject = {
	default: {
		label: "Which of the following best describe your increase in expenses? Choose all that apply.",
		selected: [],
		values: [
			"Medical expenses",
			"Funeral expenses",
			"Roommate moved out",
			"Rent increase",
			"Additional household member who you are supporting",
			"Other (please describe)",
		],
	},
};

export default function Intro2(data) {
	let dialogOpen = false;

	const {
		error,
		liveInSF,
		howManyInHousehold,
		householdMonthlyGross,
		householdPriorMonthlyGross,
		applyingNewUnit,
		recentHardship,
		recentHardshipOther,
		intro2DlgOpen,
		intro2DlgText,
		bestDescribeIncExp,
		bestDescribeIncExpArr,
		bestDescribeIncExpOther,
		bestDescribeIncExpDetail,
	} = data.formData;

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const amiTable = [5713, 6529, 7346, 8158, 8813, 9467, 10117, 10771];
	// const limits2019 = [130100, 148700, 167300, 185800, 200700, 215600, 230400, 245300];
	const limits2019 = amiTable.map(function (a) {
		console.log(a);
		return a * 2 * 12;
	});

	const houseHoldQty =
		howManyInHousehold && howManyInHousehold != ""
			? howManyInHousehold == "9+"
				? 8
				: parseInt(howManyInHousehold)
			: 0;

	const handleClose = async () => {
		await updateData("intro2DlgOpen", false);
		data.setFormData({ ...data.formData, intro2DlgOpen: false, intro2DlgText: "" });
	};

	const updateData = (field, value) => {
		data.setFormData({ ...data.formData, [field]: value });
	};

	const updateMultiData = (nData) => {
		data.setFormData({ ...data.formData, ...nData });
	};

	const openDlgAndDisplayText = (intro2DlgText) => {
		data.setFormData({ ...data.formData, intro2DlgOpen: true, intro2DlgText });
	};

	// console.log("INTRO 2 data", data);
	return (
		<React.Fragment>
			<Dialog
				open={intro2DlgOpen ? true : false}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onBackdropClick={() => updateData("intro2DlgOpen", false)}
			>
				{/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{intro2DlgText}</DialogContentText>
				</DialogContent>
			</Dialog>
			<Typography
				style={{ color: "#666", textDecoration: "underline", marginTop: 1, fontSize: "1.5rem" }}
			>
				Before proceeding with your application, please answer the following eligibility questions:
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!liveInSF || liveInSF == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="liveInSF">
							Do you currently live in San Francisco?
						</InputLabel>
						<InputLabel htmlFor="liveInSF">Do you currently live in San Francisco?</InputLabel>
						<Select
							native
							value={liveInSF}
							onChange={(evt) => {
								updateData("liveInSF", evt.target.value);
								if (evt.target.value !== "yes")
									openDlgAndDisplayText(
										<div>
											You are not eligible to apply for this program. Please{" "}
											<a href="https://seasonofsharing.org/get-help/">click here</a> for other
											rental assistance programs in the Bay Area that you may be eligible for.
										</div>
									);
							}}
							inputProps={{
								name: "liveInSF",
								id: "liveInSF",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} style={{ paddingBottom: 40 }}>
					<FormControl
						required
						error={error && (!howManyInHousehold || howManyInHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-in-household">
							How many people are in your household? (include yourself)
						</InputLabel>
						<InputLabel htmlFor="howManyInHousehold">
							How many people are in your household? (include yourself)
						</InputLabel>

						<Select
							native
							value={howManyInHousehold}
							onChange={(evt) => updateData("howManyInHousehold", evt.target.value)}
							inputProps={{
								name: "howManyInHousehold",
								id: "how-many-in-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5"}>5</option>
							<option value={"6"}>6</option>
							<option value={"7"}>7</option>
							<option value={"8"}>8</option>
							<option value={"9+"}>9+</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} className={"longText"}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						style={{ height: window.innerWidth <= 600 ? 200 : "" }}
					>
						{householdMonthlyGross && householdMonthlyGross != ""
							? null
							: /* <InputLabel
								style={{ paddingLeft: 15, marginTop: -40, height: 200 }}
								htmlFor="householdMonthlyGross"
							>
								<span>
									What is your household’s current <b>monthly</b> income?: Please include any income
									from employment (formal or informal) and cash benefits. If your income varies by
									month, please provide your income from the last 30 days. (Please note, if you have
									savings/cash resources that amount to three times your monthly rent, you will not
									be eligible for assistance from this program.)
								</span>
							</InputLabel> */
							  ""}
						<TextField
							required
							style={{
								position: window.innerWidth <= 600 ? "absolute" : "",
								bottom: window.innerWidth <= 600 ? 0 : "",
							}}
							disabled={!howManyInHousehold || howManyInHousehold == ""}
							error={error && (!householdMonthlyGross || householdMonthlyGross == "")}
							id="householdMonthlyGross"
							name="householdMonthlyGross"
							label={
								<span>
									What is your household’s current <b>monthly</b> income?: Please include any income
									from employment (formal or informal) and cash benefits. If your income varies by
									month, please provide your income from the last 30 days. (Please note, if you have
									savings/cash resources that amount to three times your monthly rent, you will not
									be eligible for assistance from this program.)
								</span>
							}
							type={"number"}
							fullWidth
							InputLabelProps={{ style: { display: "contents" } }}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
								// style: { marginTop: window.innerWidth <= 600 ? 1 : "" },
								className: "mobileLongInputLabel",
							}}
							defaultValue={householdMonthlyGross}
							onChange={async (e) => {
								await updateData(e.target.name, e.target.value);
							}}
							onBlur={(e) => {
								console.log("onBlur", e.target.value, e.target);
								if (
									parseFloat(
										((e.target.value * 12) / limits2019[parseInt(houseHoldQty) - 1]) * 100
									).toFixed(2) > 55
								)
									openDlgAndDisplayText(
										<div>
											You may not be eligible to apply for this program. Please make sure you
											correctly entered your MONTHLY income and{" "}
											<a href="https://sf.gov/renthelp" target="_blank">
												click here
											</a>{" "}
											for other resources you may be eligible for.
										</div>
									);
							}}
						/>
					</FormControl>
				</Grid>

				{householdMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" style={{ color: "#666" }}>
							Your current annual income is: ${householdMonthlyGross * 12}
						</Typography>
					</Grid>
				)}
				{householdMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
							You are{" "}
							{parseFloat(
								((householdMonthlyGross * 12) / limits2019[parseInt(houseHoldQty) - 1]) * 100
							).toFixed(2)}{" "}
							percent of the area median income
						</Typography>
					</Grid>
				)}

				<Grid item xs={12}>
					<FormControl
						required
						error={error && (!applyingNewUnit || applyingNewUnit == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="applyingNewUnit">
							Are you applying for assistance with past-due rent OR assistance with moving into a
							new unit (security deposit, etc.)?
						</InputLabel>
						<InputLabel htmlFor="needInterpretation">
							Are you applying for assistance with past-due rent OR assistance with moving into a
							new unit (security deposit, etc.)?
						</InputLabel>
						<Select
							native
							value={applyingNewUnit}
							onChange={(evt) => updateData("applyingNewUnit", evt.target.value)}
							inputProps={{
								name: "applyingNewUnit",
								id: "applyingNewUnit",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Assistance with past-due rent"}>Assistance with past-due rent</option>
							<option value={"Move-in assistance for a unit I have already identified"}>
								Move-in assistance for a unit I have already identified
							</option>
							<option value={"Both of the above"}>Both of the above</option>
						</Select>
					</FormControl>
				</Grid>

				{/* {console.log("applyingNewUnit", applyingNewUnit)} */}

				{applyingNewUnit &&
					applyingNewUnit != "" &&
					applyingNewUnit !== "Move-in assistance for a unit I have already identified" && (
						<Grid item xs={12}>
							<FormControl
								required
								error={error && (!applyingNewUnit || applyingNewUnit == "")}
								fullWidth
								onChange={(evt) => {
									updateData("recentHardship", evt.target.value);
									if (evt.target.value == "No recent financial hardship") {
										openDlgAndDisplayText(
											<div>
												You are not eligible to apply for this program. Please{" "}
												<a href="https://sf.gov/renthelp">click here</a> for other resources you may
												be eligible for.
											</div>
										);
									}
								}}
								onBlur={(e) => {
									if (e.target.value == "No recent financial hardship") {
										openDlgAndDisplayText(
											<div>
												You are not eligible to apply for this program. Please{" "}
												<a href="https://sf.gov/renthelp">click here</a> for other resources you may
												be eligible for.
											</div>
										);
									}
								}}
								className={"MuiFormControl-marginNormal longText"}
							>
								<InputLabel shrink htmlFor="recentHardship">
									What best describes your recent financial hardship (within the last 12 months)?*
								</InputLabel>
								<InputLabel htmlFor="recentHardship">
									What best describes your recent financial hardship (within the last 12 months)?*
								</InputLabel>
								<Select
									native
									required
									error={error && (!recentHardship || recentHardship == "")}
									value={recentHardship}
									onChange={(evt) => updateData("recentHardship", evt.target.value)}
									inputProps={{
										name: "recentHardship",
										id: "recentHardship",
									}}
								>
									<option aria-label="None" value="" />
									<option
										value={
											"Recent loss of income (such as loss of employment or reduced hours, loss of public benefits, or loss of income earner in the household)"
										}
									>
										Recent loss of income (such as loss of employment or reduced hours, loss of
										public benefits, or loss of income earner in the household)
									</option>
									<option
										value={
											"Significant and unexpected increase in expenses (such as recent medical emergency, funeral expenses, or roommate moved out)"
										}
									>
										Significant and unexpected increase in expenses (such as recent medical
										emergency, funeral expenses, or roommate moved out)
									</option>
									<option value={"Other (please describe)"}>Other (please describe)</option>
									<option value={"No recent financial hardship"}>
										No recent financial hardship
									</option>
								</Select>
							</FormControl>
						</Grid>
					)}

				{
					recentHardship &&
						recentHardship != "" &&
						applyingNewUnit !== "Move-in assistance for a unit I have already identified" && (
							///* recentHardship.toLowerCase().indexOf("other") >= 0 && ( */}
							<Grid item xs={12} className={"longText"}>
								<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
									{/* <InputLabel style={{ paddingLeft: 15 }} htmlFor="recentHardshipOther">
									<span>Describe your recent hardship</span>
								</InputLabel> */}
									<TextField
										required
										error={
											error &&
											(!recentHardshipOther ||
												recentHardshipOther == "" ||
												recentHardshipOther.trim() == "")
										}
										id="recentHardshipOther"
										name="recentHardshipOther"
										label={<span>Describe your recent hardship</span>}
										fullWidth
										defaultValue={recentHardshipOther}
										onChange={(e) => updateData(e.target.name, e.target.value)}
									/>
								</FormControl>
							</Grid>
						)
					//</React.Fragment>)
				}

				{recentHardship &&
					recentHardship.toLowerCase().indexOf("significant") >= 0 &&
					Object.keys(increaseInExpObject).map((ethKey) => {
						const item = (
							<Grid item xs={12} sm={12} m={6} key={`ethKey-${ethKey}`}>
								<FormControl
									fullWidth
									className={"MuiFormControl-marginNormal"}
									error={error && (!bestDescribeIncExp || bestDescribeIncExp == "")}
								>
									<InputLabel shrink htmlFor="bestDescribeIncExp">
										{increaseInExpObject[ethKey].label}
									</InputLabel>
									<InputLabel htmlFor={ethKey}> {increaseInExpObject[ethKey].label}</InputLabel>
									<Select
										// native
										value={increaseInExpObject[ethKey].selected}
										className={"multiSelect"}
										MenuProps={MenuProps}
										input={<Input />}
										name={ethKey}
										multiple
										renderValue={(selected) => selected.join(", ")}
										onChange={async (e) => {
											const nArr = e.target.value;
											increaseInExpObject[ethKey].selected = nArr;
											let allEth = [];
											Object.keys(increaseInExpObject).forEach(
												(eO) => (allEth = allEth.concat(increaseInExpObject[eO].selected))
											);

											updateMultiData({ bestDescribeIncExp: allEth.join(";") });
										}}
										inputProps={{
											name: "bestDescribeIncExpArr",
											id: ethKey,
										}}
									>
										{increaseInExpObject[ethKey].values.map((name) => {
											return (
												<MenuItem key={name} value={name}>
													<Checkbox
														className={"bacsCheckbox"}
														checked={increaseInExpObject[ethKey].selected.indexOf(name) > -1}
													/>
													<ListItemText primary={name} />
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						);
						console.log("item", item);
						return item;
					})}

				{bestDescribeIncExp &&
					bestDescribeIncExp != "" &&
					bestDescribeIncExp.toLowerCase().indexOf("other") >= 0 && (
						<Grid item xs={12} className={"longText"}>
							<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
								{/* <InputLabel style={{ paddingLeft: 15 }} htmlFor="recentHardshipOther">
									<span>Describe your recent hardship</span>
								</InputLabel> */}
								<TextField
									required
									error={
										error &&
										(!bestDescribeIncExpOther ||
											bestDescribeIncExpOther == "" ||
											bestDescribeIncExpOther.trim() == "")
									}
									id="bestDescribeIncExpOther"
									name="bestDescribeIncExpOther"
									label={<span>Describe your increase in expenses</span>}
									fullWidth
									defaultValue={bestDescribeIncExpOther}
									onChange={(evt) => updateData("bestDescribeIncExpOther", evt.target.value)}
								/>
							</FormControl>
						</Grid>
					)}

				{recentHardship && recentHardship.toLowerCase().indexOf("significant") >= 0 && (
					<Grid item xs={12}>
						<FormControl
							required
							error={error && (!bestDescribeIncExpDetail || bestDescribeIncExpDetail == "")}
							fullWidth
							className={"MuiFormControl-marginNormal longText"}
						>
							<InputLabel shrink htmlFor="bestDescribeIncExpDetail">
								Is this increased expense one-time or ongoing?
							</InputLabel>
							<InputLabel htmlFor="needInterpretation">
								Is this increased expense one-time or ongoing?
							</InputLabel>
							<Select
								native
								value={bestDescribeIncExpDetail}
								onChange={(evt) => updateData("bestDescribeIncExpDetail", evt.target.value)}
								inputProps={{
									name: "bestDescribeIncExpDetail",
									id: "bestDescribeIncExpDetail",
								}}
							>
								<option aria-label="None" value="" />
								<option value={"One-time expense"}>One-time expense</option>
								<option value={"Ongoing expense"}>Ongoing expense</option>
							</Select>
						</FormControl>
					</Grid>
				)}

				{recentHardship && recentHardship.toLowerCase().indexOf("recent loss") >= 0 && (
					<Grid item xs={12} className={"longText"}>
						<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
							{/* {householdPriorMonthlyGross && householdPriorMonthlyGross != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="householdPriorMonthlyGross">
									What was your MONTHLY household income PRIOR to your household’s loss of income?
									Please include any income from employment (formal or informal) and cash benefits.
									If your income varied by month, please provide your income from the 30 days prior
									to the loss of income.
								</InputLabel>
							)} */}
							<TextField
								required
								error={error && (!householdPriorMonthlyGross || householdPriorMonthlyGross == "")}
								id="householdPriorMonthlyGross"
								name="householdPriorMonthlyGross"
								label={
									<span>
										What was your MONTHLY household income PRIOR to your household’s loss of income?
										Please include any income from employment (formal or informal) and cash
										benefits. If your income varied by month, please provide your income from the 30
										days prior to the loss of income.
									</span>
								}
								type={"number"}
								fullWidth
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								defaultValue={householdPriorMonthlyGross}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				)}
			</Grid>
		</React.Fragment>
	);
}
