import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import CircularProgress from "@material-ui/core/CircularProgress";

const formatUSNumber = (entry = "") => {
	if (!entry || entry.length < 1) return entry;
	try {
		const match = entry
			.replace(/\D+/g, "")
			.replace(/^1/, "")
			.match(/([^\d]*\d[^\d]*){1,10}$/)[0];
		const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
		const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
		const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
		return `${part1}${part2}${part3}`;
	} catch (e) {
		return entry;
	}
};

export default function SubmissionForm(formData) {
	let esig = React.useRef();

	const {
		error,
		agreed,
		signedName,
		whatCity,
		submittedBy,
		agreedTrue,
		agreedFalsification,
		agreedAssistance,
		agreedCityConsent,
		agreedCommunications,
		agreedParticipate,
		repName,
		repEmail,
		repPhone,
		repRelationship,
		repRelationshipOther,
	} = formData.formData;

	const { isSubmitting, setSubmitting } = formData;

	const updateData = (field, value) => {
		formData.setFormData({ ...formData.formData, [field]: value });
	};

	// console.log("isSubmitting", isSubmitting, setSubmitting, formData);
	return (
		<React.Fragment>
			{isSubmitting && (
				<div>
					{" "}
					<CircularProgress /> <div>Submitting Form - Please Wait</div>
				</div>
			)}
			{!isSubmitting && (
				<div>
					<Typography
						variant="h6"
						gutterBottom
						style={{ color: "#666", fontSize: "1rem", fontWeight: 800, paddingBottom: 50 }}
					>
						Once you click ‘Submit’ below, you will receive a confirmation email with information on
						next steps. This is proof that your application was successfully submitted. It may be
						eight weeks or more until your application is processed. If you have been contacted by a
						case worker, you may reach out to them to get a status update. Otherwise, please be
						patient until a case worker contacts you to confirm the information on your application
						and do not reapply as this will further delay your application..
					</Typography>

					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Consent and Release of Information
					</Typography>

					<Grid container spacing={3} style={{ textAlign: "left" }}>
						<Grid item xs={12}>
							<FormControl required error={error && (!agreedTrue || agreedTrue !== "yes")}>
								<FormControlLabel
									label={
										"By submitting this application, I certify that all information provided in this application is true, accurate and complete, and I will provide additional documentation to support any representations."
									}
									control={
										<Checkbox
											color="secondary"
											name="agreed"
											control={agreedTrue.toString()}
											value={agreedTrue ? "yes" : false}
											checked={agreedTrue}
											onChange={(evt, agreedTrue) => {
												formData.setFormData({ ...formData.formData, agreedTrue });
											}}
										/>
									}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								label={
									"I further acknowledge that falsification of any information or any material falsehoods or omissions in the Application, including knowingly seeking duplicative benefits, is subject to state and federal penalties including fines and criminal prosecution.  It could also result in denial of my application or in a government agency, its agents, or employees nullifying my application and seeking to reclaim any financial assistance that I received.​"
								}
								control={
									<Checkbox
										color="secondary"
										name="agreedFalsification"
										control={agreedFalsification.toString()}
										value={agreedFalsification ? "yes" : false}
										checked={agreedFalsification}
										onChange={(evt, agreedFalsification) => {
											formData.setFormData({ ...formData.formData, agreedFalsification });
										}}
									/>
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								label={
									<span>
										I acknowledge that by submitting this application, I am in no way guaranteed
										financial assistance. I also understand that meeting the{" "}
										<a href="https://www.sf.gov/information/sf-erap-program-rules" target="_blank">
											Program’s eligibility and/or selection criteria
										</a>{" "}
										does not guarantee that I will be selected for assistance.
									</span>
								}
								control={
									<Checkbox
										color="secondary"
										name="agreedAssistance"
										control={agreedAssistance.toString()}
										value={agreedAssistance ? "yes" : false}
										checked={agreedAssistance}
										onChange={(evt, agreedAssistance) => {
											formData.setFormData({ ...formData.formData, agreedAssistance });
										}}
									/>
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								label={
									"I give consent/authorization for the City of San Francisco and its respective agents, employees and assigns, and Bay Area Community Services and All Home, as operators/administrators of the Regional Homelessness Prevention Network (“Network”), to share, disclose, analyze, and discuss all documentation and information provided in this application in furtherance of offering me assistance, to prevent duplication of benefits, or to present aggregate data about the Emergency Rental Assistance Program or the Homelessness Prevention Network. ​"
								}
								control={
									<Checkbox
										color="secondary"
										name="agreedCityConsent"
										control={agreedCityConsent.toString()}
										value={agreedCityConsent ? "yes" : false}
										checked={agreedCityConsent}
										onChange={(evt, agreedCityConsent) => {
											formData.setFormData({ ...formData.formData, agreedCityConsent });
										}}
									/>
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								label={
									"I acknowledge that electronic communications (e.g., text messages) are not secure; I hereby authorize any service provider who is working with me to communicate with me regarding this program using electronic communications and have provided the phone number in the application to enable text message communication. And, I understand that text messaging is to be used for the purposes of communicating on a limited basis and is not intended to provide additional support.  If I am in need of additional support when I receive a text message, I understand that I should not respond by text to my service provider; rather, I should contact the service provider via phone, email, or at the office of the service provider. I understand that I can revoke this provision regarding text message communications at any time during service. "
								}
								control={
									<Checkbox
										color="secondary"
										name="agreedCommunications"
										control={agreedCommunications.toString()}
										value={agreedCommunications ? "yes" : false}
										checked={agreedCommunications}
										onChange={(evt, agreedCommunications) => {
											formData.setFormData({ ...formData.formData, agreedCommunications });
										}}
									/>
								}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								label={
									"I agree to participate in the evaluation of the emergency rental assistance program and/or the regional homelessness prevention network, which will help improve future service delivery and potentially expand resources for services, rental support and affordable housing.  Your willingness to participate in any future evaluation process will not affect your eligibility or selection for this program in any way.  Participation is not required. "
								}
								control={
									<Checkbox
										color="secondary"
										name="agreedParticipate"
										control={agreedParticipate.toString()}
										value={agreedParticipate ? "yes" : false}
										checked={agreedParticipate}
										onChange={(evt, agreedParticipate) => {
											formData.setFormData({ ...formData.formData, agreedParticipate });
										}}
									/>
								}
							/>
						</Grid>
						<Grid item xs={4} implementation="css" />
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										color="secondary"
										name="agreed"
										control={agreed.toString()}
										value={agreed ? "yes" : false}
										checked={agreed}
										onChange={(evt, agreed) => {
											formData.setFormData({ ...formData.formData, agreed });
											if (agreed)
												setTimeout(() => {
													esig && esig.current.focus();
												}, 250);
										}}
									/>
								}
								label="I certify that the information submitted in this application is true and accurate. I acknowledge receipt of all information provided to me in the paragraphs above. I consent to the release of information as described above, and I understand that I have the right to revoke this consent in writing at any time.​"
							/>
						</Grid>
						<Grid item xs={4} implementation="css" />
						<Grid item xs={4}>
							<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
								<InputLabel shrink htmlFor="submittedBy">
									Form Submitted By
								</InputLabel>
								<InputLabel htmlFor="submittedBy">Form Submitted By</InputLabel>
								<Select
									native
									value={submittedBy}
									// onChange={handleChange}
									name={"submittedBy"}
									onChange={(e) =>
										formData.setFormData({
											...formData.formData,
											submittedBy: e.target.value,
										})
									}
									inputProps={{
										name: "submittedBy",
										id: "submittedBy",
									}}
								>
									<option aria-label="None" value="MYSELF">
										MYSELF
									</option>
									<option aria-label="None" value="68201">
										68201
									</option>
									<option aria-label="None" value="68202">
										68202
									</option>
									<option aria-label="None" value="68203">
										68203
									</option>
									<option aria-label="None" value="68204">
										68204
									</option>
									<option aria-label="None" value="68205">
										68205
									</option>
									<option aria-label="None" value="68206">
										68206
									</option>
									<option aria-label="None" value="68207">
										68207
									</option>
									<option aria-label="None" value="68208">
										68208
									</option>
									<option aria-label="None" value="68209">
										68209
									</option>
									<option aria-label="None" value="68210">
										68210
									</option>
									<option aria-label="None" value="79301">
										79301
									</option>
									<option aria-label="None" value="79302">
										79302
									</option>
									<option aria-label="None" value="79303">
										79303
									</option>
									<option aria-label="None" value="79304">
										79304
									</option>
									<option aria-label="None" value="79305">
										79305
									</option>
									<option aria-label="None" value="79306">
										79306
									</option>
									<option aria-label="None" value="79307">
										79307
									</option>
									<option aria-label="None" value="79308">
										79308
									</option>
									{/* <option aria-label="None" value="79309">79309</option>
                  <option aria-label="None" value="79310">79310</option>
                  <option aria-label="None" value="79311">79311</option> */}
									<option aria-label="None" value="79312">
										79312
									</option>
									<option aria-label="None" value="89300">
										89300
									</option>
									<option aria-label="None" value="99400">
										99400
									</option>
									<option aria-label="None" value="99401">
										99401
									</option>
									<option aria-label="None" value="SDSP Only">
										SDSP Only
									</option>
									<option aria-label="None" value="For SoS Admin. Use Only">
										For SoS Admin. Use Only
									</option>
									<option aria-label="None" value="PSH Provider Admin Use Only">
										PSH Provider Admin Use Only
									</option>
									<option aria-label="None" value="RAD or HOPE SF Provider Use Only">
										RAD or HOPE SF Provider Use Only
									</option>
									<option aria-label="None" value="HSH Problem Solving Provider">
										HSH Problem Solving Provider
									</option>
									<option aria-label="None" value="HSH Prevention HLA">
										HSH Prevention HLA
									</option>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								control
								required
								control={
									formData.formData.signedName ? formData.formData.signedName.toString() : ""
								}
								disabled={!agreed}
								onChange={(evt) =>
									formData.setFormData({
										...formData.formData,
										signedName: evt.target.value,
									})
								}
								value={signedName && agreed ? signedName : ""}
								inputRef={esig}
								focused={agreed}
								id="electronic-signature"
								name="electronicSignature"
								label="Type your full name for electronic signature submission"
								fullWidth
							/>
						</Grid>
					</Grid>
					<Typography
						style={{ color: "#666", textDecoration: "underline", marginTop: "2rem", color: "red" }}
					>
						You will receive an email confirmation of your submission. Please do not submit more
						than 1 application per household. Submitting more than 1 application could slow down
						your application processing.
					</Typography>

					<Typography variant="h6" gutterBottom style={{ color: "#666", marginTop: "1.5rem" }}>
						Authorized Representative
					</Typography>

					<Typography variant="h6" gutterBottom>
						If you are working with someone to submit this application (such as a social worker,
						case worker, attorney, friend or family member) and would like to authorize them to
						access information about your application, please provide their information below.
					</Typography>

					<Grid item xs={12} sm={6}>
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							<InputLabel shrink htmlFor="repName">
								Representative First & Last Name
							</InputLabel>
							<TextField
								id="repName"
								name="repName"
								label="Representative First & Last Name"
								fullWidth
								autoComplete="repName"
								defaultValue={repName}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl
							fullWidth
							className={"MuiFormControl-marginNormal"}
							error={error && (!repEmail || repEmail == "")}
						>
							<InputLabel shrink htmlFor="repEmail">
								Representative's Email
							</InputLabel>
							<TextField
								id="repEmail"
								name="repEmail"
								label="Representative's Email"
								fullWidth
								autoComplete="repEmail"
								defaultValue={repEmail}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl fullWidth>
							<InputLabel shrink htmlFor="repPhone">
								Representative's Phone
							</InputLabel>

							<TextField
								id="repPhone"
								aria-describedby="phone-helper-text"
								name={"repPhone"}
								value={repPhone ? repPhone : ""}
								label={"Representative's Phone"}
								onChange={(e) =>
									updateData(
										e.target.name,
										formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
									)
								}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							<InputLabel shrink htmlFor="how-did-you-hear-about-us">
								Representative’s Relationship to You
							</InputLabel>
							<InputLabel htmlFor="how-did-you-hear-about-us-input">
								Representative’s Relationship to You
							</InputLabel>
							<Select
								native
								value={repRelationship}
								onChange={(evt) => updateData("repRelationship", evt.target.value)}
								inputProps={{
									name: "repRelationship",
									id: "repRelationship",
								}}
							>
								<option value={""}></option>
								<option value={"Attorney"}>Attorney</option>
								<option value={"Community organization (such as a social worker or advocate)"}>
									Community organization (such as a social worker or advocate)
								</option>
								<option value={"Family Member"}>Family Member</option>
								<option value={"Friend"}>Friend</option>
								<option value={"Other"}>Other</option>
							</Select>
						</FormControl>
					</Grid>

					{repRelationship && repRelationship == "Other" ? (
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
								<InputLabel shrink htmlFor="repRelationshipOther">
									Describe Relationship
								</InputLabel>
								<TextField
									id="repRelationshipOther"
									name="repRelationshipOther"
									label="Describe Relationship"
									fullWidth
									autoComplete="repRelationshipOther"
									defaultValue={repRelationshipOther}
									onChange={(e) => updateData(e.target.name, e.target.value)}
								/>
							</FormControl>
						</Grid>
					) : null}
				</div>
			)}
		</React.Fragment>
	);
}
